<template>
  <div class="funandsnow">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <ImageLoader :url_small="'display_start_little'" :url="'display_start'"/>
        </div>
        <div class="col-md-7">
          <span class="highlight_themed">Projekt:</span> Einzelhandel-Verwaltung<br>
          <span class="highlight_themed">Dauer:</span> fortlaufend<br>
          <span class="highlight_themed">Techstack:</span> PHP, MySQL, Laravel, VueJS, RESTApi<br>
          <span class="highlight_themed">Ziel:</span><br>
          Ein System zur Verwaltung des lokalen Einzelhandel. Dazunter fallen diverse Exportmöglichkeiten und Transfersysteme von Platformen wie Planovo zu Datev.
          Dazu eine Zentrale Geräteverwaltung inkl. Restartplanungsfunktion. Auch Bildschirme mit simplen Bildanzeigen und komplizierten Verschachtelungen sind möglich.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLoader from "@/components/layout/ImageLoader";
export default {
  name: "ReferenzEinzelhandel",
  components: {ImageLoader}
}
</script>

<style scoped>
.funandsnow{
  margin-bottom: 3em;
}
/deep/ img{
  width: 80%;
  margin-bottom: 0.5em;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

</style>
