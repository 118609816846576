import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'material-icons/iconfont/material-icons.css';
import router from "./routes"
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = 'http://localhost/ottsoftApi/public/api';
axios.defaults.withCredentials = true;

const app = createApp(App)
app.use(router)
app.use(VueAxios, axios)

app.mount('#app')
