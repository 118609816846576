<template>
  <div class="funandsnow">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <ImageLoader :url_small="'c_consulting_little'" :url="'c_consulting'"/>
        </div>
        <div class="col-md-7">
          <span class="highlight_themed">Projekt:</span> C-Consulting<br>
          <span class="highlight_themed">Dauer:</span> 2 Monate<br>
          <span class="highlight_themed">Techstack:</span> PHP, MySQL, Laravel<br>
          <span class="highlight_themed">Ziel:</span><br>
          Ein gegebenes Design mit einem Light-CMS System verknüpfen und modular Anpassbar machen. Das Light-CMS System wurde von uns entwickelt und auf das Konzept optimiert.

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLoader from "@/components/layout/ImageLoader";
export default {
  name: "ReferenzCConsulting",
  components: {ImageLoader}
}
</script>

<style scoped>
.funandsnow{
  margin-bottom: 3em;
}
/deep/ img{
  width: 80%;
  margin-bottom: 0.5em;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

</style>
