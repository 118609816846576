<template>
  <div class="funandsnow">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <ImageLoader :url_small="'ntc_website_little'" :url="'ntc_webseite'"/>
        </div>
        <div class="col-md-7">
          <span class="highlight_themed">Projekt:</span> Webseite auf Joomla Basis<br>
          <span class="highlight_themed">Dauer:</span> 1 Monat<br>
          <span class="highlight_themed">Techstack:</span> PHP, MySQL, Joomla, JS, SEO<br>
          <span class="highlight_themed">Ziel:</span><br>
         Implementierung eines Designs in Joomla. Schreiben mehrere Module, die das Verwalten der Seite vereinfachen.

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLoader from "@/components/layout/ImageLoader";
export default {
  name: "ReferenzNTC",
  components: {ImageLoader}
}
</script>

<style scoped>
.funandsnow{
  margin-bottom: 3em;
}
/deep/ img{
  width: 80%;
  margin-bottom: 0.5em;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>
