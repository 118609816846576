<template>
  <TippingReferenz></TippingReferenz>
  <ReferenzFunAndSnow />
  <div class="container underline"></div>
  <br>
  <ReferenzCConsulting />
  <div class="container underline"></div>
  <br>
  <ReferenzEinzelhandel />
  <div class="container underline"></div>
  <br>
  <ReferenzHosterHoster />
  <div class="container underline"></div>
  <br>
  <ReferenzNTC />
  <div class="container underline"></div>
  <br>
  <KontactShow />
</template>

<script>
import ReferenzFunAndSnow from "@/components/Reverenzen/ReferenzFunAndSnow/ReferenzFunAndSnow";
import ReferenzCConsulting from "@/components/Reverenzen/ReferenzCConsulting/ReferenzCConsulting";
import KontactShow from "@/components/layout/KontactShow/KontactShow";
import ReferenzEinzelhandel from "@/components/Reverenzen/ReferenzEinzelhandel/ReferenzEinzelhandel";
import ReferenzHosterHoster from "@/components/Reverenzen/ReferenzHosterHoster/ReferenzHosterHoster";
import ReferenzNTC from "@/components/Reverenzen/ReferenzNTC/ReferenzNTC";
import TippingReferenz from "@/components/TippingBox/TippingReferenz";
export default {
  name: "ReferenzView",
  components: {
    TippingReferenz,
    ReferenzNTC,
    ReferenzHosterHoster, ReferenzEinzelhandel, KontactShow, ReferenzCConsulting, ReferenzFunAndSnow}
}
</script>

<style scoped>

</style>
