<template>
  <div class="funandsnow">
    <div class="container">

      <div class="row">
        <div class="col-md-5">
          <ImageLoader :url_small="'fun_and_snow_little'" :url="'fun_and_snow'"/>
        </div>
        <div class="col-md-7">
          <span class="highlight_themed">Projekt:</span> FunAndSnow<br>
          <span class="highlight_themed">Dauer:</span> fortlaufend<br>
          <span class="highlight_themed">Techstack:</span> Wordpress, PHP, MySQL, Laravel, Telegram<br>
          <span class="highlight_themed">Ziel:</span><br>
          Als Agentur wurde dem Kunden eine Software entwickelt, die über mehrere Channels Buchungen annimmt und diese verteilt. Die Webseite wurde mit Wordpress realisiert und das Backend mit Laravel und AdminLTE. Damit das System so intuitiv wie möglich bleibt,
          wurde eine Verknüpfung mit Telegram geschaffen, sodass Guides bei neuen Buchungen direkt über einen Chatchannel informiert werden.

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ImageLoader from "@/components/layout/ImageLoader";


export default {
  name: "ReferenzFunAndSnow",
  components: {ImageLoader},
}
</script>

<style scoped>
.funandsnow{
  margin-bottom: 3em;
}
/deep/ img{
  width: 80%;
  margin-bottom: 0.5em;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

</style>
